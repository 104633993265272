import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashModule)
  },
  {
    path: 'cammino',
    loadChildren: () => import('./pages/cammino/cammino.module').then( m => m.CamminoModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'municipality/list',
    loadChildren: () => import('./pages/municipality/list/list.module').then( m => m.MunicipalityListPageModule)
  },
  {
    path: 'municipality/:id',
    loadChildren: () => import('./pages/municipality/detail/detail.module').then( m => m.MunicipalityDetailPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/route/list/list.module').then( m => m.RouteListPageModule)
  },
  {
    path: 'attrattore/list',
    loadChildren: () => import('./pages/attrattore/list/list.module').then( m => m.AttrattoreListPageModule)
  },
  {
    path: 'attrattore/:id',
    loadChildren: () => import('./pages/attrattore/detail/detail.module').then( m => m.AttrattoreDetailPageModule)
  },
  {
    path: 'route/:id',
    loadChildren: () => import('./pages/route/detail/detail.module').then( m => m.RouteDetailPageModule)
  },
  {
    path: 'ar',
    loadChildren: () => import('./pages/ar/ar.module').then( m => m.ArPageModule)
  },
  {
    path: 'ar/:id',
    loadChildren: () => import('./pages/ar/ar.module').then( m => m.ArPageModule)
  },
  {
    path: 'videogallery',
    loadChildren: () => import('./pages/videogallery/videogallery.module').then( m => m.VideoGalleryModule)
  },
  {
    path: 'eventi',
    loadChildren: () => import('./pages/eventi/eventi.module').then( m => m.EventiModule)
  },
  {
    path: 'tracciati',
    loadChildren: () => import('./pages/tracciati/tracciati.module').then(m => m.TracciatiPageModule)
  },

  
  { path: 'info-ar', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule) },
  { path: 'privacy-policy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
