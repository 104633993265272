import { Injectable } from '@angular/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  public description: string;
  public language: string;
  public subject = new Subject<any>();
  public event = this.subject.asObservable();

  constructor(
    public globalization: Globalization,
    public translate: TranslateService
  ) {
    this.getDeviceLanguage();
  }

  initialiseTranslation(): void {
    const langCode = localStorage.getItem('langCode');
    if(!langCode) {
      this.translate.getTranslation(this.language).subscribe((res: any) => {
        this.subject.next(res);
        localStorage.setItem('lang', JSON.stringify(res));
        document.documentElement.lang = this.language;
      });
    } else {
      this.translate.getTranslation(langCode).subscribe((res: any) => {
        this.subject.next(res);
        localStorage.setItem('lang', JSON.stringify(res));
        document.documentElement.lang = langCode;
      });
    }
  }

  translateLanguage(lang): void {
    this.translate.use(lang);
    this.initialiseTranslation();
  }

  initTranslate(language) {
    // Set the default language for translation strings, and the current language.
    if (language.includes('en')) {
      this.language = 'en-EN';
      this.translate.setDefaultLang('en-EN');
      localStorage.setItem('langCode', 'en-EN');
    } else if(language.includes('it')) {
      this.language = 'it-IT';
      this.translate.setDefaultLang('it-IT');
      localStorage.setItem('langCode', 'it-IT');
    } else {
      // Set your language here
      this.language = 'it-IT';
      this.translate.setDefaultLang('it-IT');
      localStorage.setItem('langCode', 'it-IT');
    }
    this.translateLanguage(this.language);
  }

  getDeviceLanguage() {
    if (window.Intl && typeof window.Intl === 'object') {
      if(localStorage.getItem('langCode')) {
        this.initTranslate(localStorage.getItem('langCode'));
      } else {
        this.initTranslate(navigator.language);
      }
    }
    else {
      this.globalization.getPreferredLanguage()
        .then(res => {
          if(localStorage.getItem('langCode')) {
            this.initTranslate(localStorage.getItem('langCode'));
          } else {
            this.initTranslate(res.value);
          }
        })
        .catch(e => {console.log(e);});
    }
  }

  getTranslations() {
    if(localStorage.getItem('lang')) {
      return JSON.parse(localStorage.getItem('lang'));
    }
  }

  setLanguage(lang) {
    this.initTranslate(lang);
    localStorage.setItem('langCode', lang);

    location.reload();
  }

  getLanguage() {
    return localStorage.getItem('langCode');
  }

}
