import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InternationalizationService } from './services/internationalization.service';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public translations: any;
  public appPages = [];
  fontWeight: string;
  connectSubscription;
  disconnectSubscription;

  constructor(
    private platform: Platform,
    public i18n: InternationalizationService,
    private mobileAccessibility: MobileAccessibility,
    private network: Network,
    public toastController: ToastController
  ) { }

  ngOnInit(): void {
    const self = this;
    this.i18n.event.subscribe((res) => {
      self.translations = res;
      self.appPages = [
        /*{ title: 'Home', url: '/home', icon: 'home', aria: 'false' },*/
        { title: 'Home', url: '/splash', icon: 'home', aria: 'false' },
        /*{ title: res.augmentedReality, url: '/info-ar', icon: 'game-controller', aria: 'false' },*/
        { title: res.menuMap, url: '/map', icon: 'map', aria: 'true' },
        { title: res.menuComuni, url: '/municipality/list', icon: 'business', aria: 'false' },
        { title: res.menuPercorsi, url: '/route/list', icon: 'bicycle', aria: 'false' },
        { title: res.menuAttrattori, url: '/attrattore/list', icon: 'golf', aria: 'false' },
        { title: res.menuCammino, url: '/cammino', icon: 'home', aria: 'false' },
        { title: res.privacyTitle, url: '/privacy-policy', icon: 'information-circle', aria: 'false' }
      ];
    });

    this.platform.ready().then(() => {
      if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('capacitor')) {
        this.mobileAccessibility.usePreferredTextZoom(true);

        this.mobileAccessibility.isBoldTextEnabled().then((bold) => {
          if(bold) {
            this.fontWeight = 'bold';
          } else {
            this.fontWeight = 'normal';
          }
        });
      }
    });

    this.platform.ready().then(() => {
      this.onDisconnect();
    });
  }

  async messageOnDisconnect() {
    const toast = await this.toastController.create({
      message: this.translations.networkDisconnect,
      duration: 4000,
      color: 'danger'
    });
    toast.present();
  }

  goToContact() {
    window.open(environment.appURL + '/contatti');
  }

  onDisconnect() {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
      this.messageOnDisconnect();
    });
  }

  ngOnDestroy(): void {
    this.disconnectSubscription.unsubscribe();
  }

  setEN() {
    this.i18n.setLanguage('en-EN');
  }

  setIT() {
    this.i18n.setLanguage('it-IT');
  }

}
